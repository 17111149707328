import Keycloak from "keycloak-js";

// Localhost deployment
// TESTING ONLY - CHANGE CLIENT UPON FINISHING TESTING
// const my_keycloak = new Keycloak({
//     "realm": "INERGY",
//     "url": "https://keycloak.inergy.epu.ntua.gr/",
//     "clientId": "inergy_inventory_local"
// })


// ICCS deployment configuration
const my_keycloak = new Keycloak({
    "realm": "INERGY",
    "url": "https://keycloak.inergy.epu.ntua.gr/",
    "clientId": "inergy_inventory"
})

export default my_keycloak